// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-mdx": () => import("./../src/pages/accessibility.mdx" /* webpackChunkName: "component---src-pages-accessibility-mdx" */),
  "component---src-pages-addons-mdx": () => import("./../src/pages/addons.mdx" /* webpackChunkName: "component---src-pages-addons-mdx" */),
  "component---src-pages-ajax-mdx": () => import("./../src/pages/ajax.mdx" /* webpackChunkName: "component---src-pages-ajax-mdx" */),
  "component---src-pages-all-props-mdx": () => import("./../src/pages/all-props.mdx" /* webpackChunkName: "component---src-pages-all-props-mdx" */),
  "component---src-pages-animations-mdx": () => import("./../src/pages/animations.mdx" /* webpackChunkName: "component---src-pages-animations-mdx" */),
  "component---src-pages-creating-tooltips-mdx": () => import("./../src/pages/creating-tooltips.mdx" /* webpackChunkName: "component---src-pages-creating-tooltips-mdx" */),
  "component---src-pages-customizing-tooltips-mdx": () => import("./../src/pages/customizing-tooltips.mdx" /* webpackChunkName: "component---src-pages-customizing-tooltips-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-getting-started-mdx": () => import("./../src/pages/getting-started.mdx" /* webpackChunkName: "component---src-pages-getting-started-mdx" */),
  "component---src-pages-html-content-mdx": () => import("./../src/pages/html-content.mdx" /* webpackChunkName: "component---src-pages-html-content-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-lifecycle-hooks-mdx": () => import("./../src/pages/lifecycle-hooks.mdx" /* webpackChunkName: "component---src-pages-lifecycle-hooks-mdx" */),
  "component---src-pages-methods-mdx": () => import("./../src/pages/methods.mdx" /* webpackChunkName: "component---src-pages-methods-mdx" */),
  "component---src-pages-misc-mdx": () => import("./../src/pages/misc.mdx" /* webpackChunkName: "component---src-pages-misc-mdx" */),
  "component---src-pages-motivation-mdx": () => import("./../src/pages/motivation.mdx" /* webpackChunkName: "component---src-pages-motivation-mdx" */),
  "component---src-pages-plugins-mdx": () => import("./../src/pages/plugins.mdx" /* webpackChunkName: "component---src-pages-plugins-mdx" */),
  "component---src-pages-themes-mdx": () => import("./../src/pages/themes.mdx" /* webpackChunkName: "component---src-pages-themes-mdx" */),
  "component---src-pages-tippy-instance-mdx": () => import("./../src/pages/tippy-instance.mdx" /* webpackChunkName: "component---src-pages-tippy-instance-mdx" */)
}

