import { ALL_PLACEMENTS, EXTRA_ANIMATIONS } from "../../../../src/utils";
import Dropdown from "../../../../src/components/examples/Dropdown";
import Singleton from "../../../../src/components/examples/Singleton";
import Nesting from "../../../../src/components/examples/Nesting";
import Multiple from "../../../../src/components/examples/Multiple";
import React from 'react';
export default {
  ALL_PLACEMENTS,
  EXTRA_ANIMATIONS,
  Dropdown,
  Singleton,
  Nesting,
  Multiple,
  React
};